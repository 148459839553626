
function xtBrochureEvent(selector, pdfName) {
    $(selector).on('click', function() {
        ga('send', 'pageview', pdfName);
    });    
}


$(document).ready(function() {
    $(".tp-bullets .bullet, .pi-slider-page").on('click', function() {
        ga('send', 'event', 'slider actions', 'slider bullet pressed' );
    });

    $(".tp-leftarrow, .pi-slider-arrow-right").on('click', function() {
        ga('send', 'event', 'slider actions', 'previous slide pressed' );
    });
    $(".tp-rightarrow, .pi-slider-arrow-left").on('click', function() {
        ga('send', 'event', 'slider actions', 'next slide pressed' );
    });

    $(".email_link").on('click', function(event) {
        ga('send', 'event', 'email actions', event.target.id + ' clicked.' );
    });

    $("#request-more-info-button").on('click', function(event) {
        ga('send', 'event', 'actions', 'Header More Info' );
    });

    $("#submitButton").on('click', function(event) {
        ga('send', 'event', 'actions', 'Form Submitted' );
    });

    //
    // $(':input').blur(function () {
    //     ga('send', 'event', 'solutions actions', 'Form field input: ' + $(this).attr('name') );
    // });

});

jQuery(document).ready(function() {

    function checkConsentCookie() {
        var wrapper = $('.cookie_wrapper');
        if(!wrapper)return;

        var disclaimer = getCookie("ctek-cookie-disclaimer");
        if (!disclaimer || disclaimer == "") {
            setCookie("ctek-cookie-disclaimer", "1", "1");
            wrapper.show();
        } else if (disclaimer == "1"){
            setCookie("ctek-cookie-disclaimer", "2", "1");
            wrapper.show();
        } else if (disclaimer == "2"){
            setCookie("ctek-cookie-disclaimer", "3", "30");
            wrapper.show();
        }
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }


    checkConsentCookie();

    //set cookie on click
    $('#close-cookie-policy').click(function () {
        $('.cookie_wrapper').hide();
        setCookie("ctek-cookie-disclaimer", "3", 365);
    });

});
